import { useEffect, useState, useRef, useLayoutEffect, useContext } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';

import { isEmptyString, preprocessNumber, isEmail, useValidateTeacher } from '../../../lib/utils';
import { RadioItemStateEnum } from '../../atoms/RadioItem/types';
import { COURSE_ROUTES } from '../../../lib/constants';
import { CourseTypeEnum } from '../../../modules/course/types';
import { UserContext } from '../../../modules/user/UserContext';

import {
  CourseBasicInfoForm,
  CourseBasicInfoFormErrors,
  CreateCourseBasicDetailsBlockCombinedProps,
  DefaultCourseType,
  CoTeacherErrorStates,
} from './types';
import styles from './CreateCourseBasicDetailsBlock.module.scss';
import {
  getCourseByIdUseCase,
  validateTeacherByEmailUseCase,
} from './CreateCourseBasicDetailsBlock.interactor';
import { normalizeCoTeacherRepresentation } from './utils';
import { AuthContext } from '../../../modules/auth';

const initialCourseBasicInfo: CourseBasicInfoForm = {
  name: '',
  type: DefaultCourseType,
  description: '',
  registrationOpen: true,
  coTeachers: [],
  classCapacity: 0,
};

const initialCourseBasicInfoErrors: CourseBasicInfoFormErrors = {
  name: '',
  type: '',
  description: '',
  classCapacity: '',
  coTeachers: '',
};

const usePresenter = (props: CreateCourseBasicDetailsBlockCombinedProps): CreateCourseBasicDetailsBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { account } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { state: locationState } = useLocation<Record<string, object>>();
  const { id } = useParams<{ id?: string }>();
  const [formState, setFormState] = useState<CourseBasicInfoForm>({
    ...initialCourseBasicInfo,
    registrationOpen: (locationState?.step1 as CourseBasicInfoForm)?.registrationOpen === undefined 
      ? true : (locationState?.step1 as CourseBasicInfoForm)?.registrationOpen,
    description: t(`create_course.basic_info.course_descriptions.${DefaultCourseType}`),
  });
  const [formErrorState, setFormErrorState] = useState<CourseBasicInfoFormErrors>(initialCourseBasicInfoErrors);
  const [showCoTeacherField, setShowCoTeacherField] = useState(false);
  const [coTeacherInputValue, setCoTeacherInputValue] = useState<string>('');
  const [isViewerPrimaryTeacher, setIsViewerPrimaryTeacher] = useState(true);

  const coTeacherInputRef = useRef<Typeahead>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: validateTeacher } = useMutation(
    ['validateTeacher'],
    async (teacherEmail: string) => {
      return validateTeacherByEmailUseCase(teacherEmail);
    },
  );

  const { data: course } = useQuery(['getCourseById', id], () => {
    if (id) {
      return getCourseByIdUseCase(Number(id));
    }
  });

  useValidateTeacher(account, course);

  useEffect(() => {
    if (user && locationState?.step1 && course) {
      const isPrimaryTeacherViewing = course?.content?.primaryTeacher?.content?.email === user?.content?.email;
      setIsViewerPrimaryTeacher(isPrimaryTeacherViewing);
    }
  }, [user, locationState, course]);

  useEffect(() => {
    if (locationState?.step1) {
      const formData = locationState?.step1 as CourseBasicInfoForm;
      setFormState({ ...formState, ...formData });
      setShowCoTeacherField(!!formData.coTeachers?.length);
    }
  }, [locationState?.step1]);

  useLayoutEffect(() => {
    if (locationState?.step1) {
      coTeacherInputRef.current?.blur();
      textInputRef.current?.focus();
    }
  }, [locationState?.step1]);

  const handleTextChange = (field: keyof CourseBasicInfoForm) => {
    return (value: string) => {
      setFormState({
        ...formState,
        [field]: field === 'classCapacity' ? parseInt(value) : value,
      });
      setFormErrorState({
        ...formErrorState,
        [field]: '',
      });
    };
  };

  const handleRadioSelection = (value: CourseTypeEnum) => {
    // change type and description accordingly
    setFormState({
      ...formState,
      type: value,
      description: t(`create_course.basic_info.course_descriptions.${value}`),
    });
  };

  useEffect(() => {
    if (course) {
      const coTeachers = course.content.coTeachers?.map((coTeacher) => coTeacher.content.email);
      setFormState({
        ...formState,
        classCapacity: course.content.classCapacity,
        description: course.content.description,
        name: course.content.title,
        type: course.content.courseType,
        coTeachers: coTeachers,
      });
      setShowCoTeacherField(!!coTeachers?.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  const validateForm = (formStateOnSubmit: CourseBasicInfoForm): CourseBasicInfoFormErrors => {
    let formErrors = {
      ...initialCourseBasicInfoErrors,
    };
    if (isEmptyString(formStateOnSubmit.name)) {
      formErrors = {
        ...formErrors,
        name: t('create_course.basic_info.labels.errors.required_field'),
      };
    }
    if (isEmptyString(formStateOnSubmit.description)) {
      formErrors = {
        ...formErrors,
        description: t('create_course.basic_info.labels.errors.required_field'),
      };
    }
    if (isNaN(formStateOnSubmit.classCapacity)) {
      formErrors = {
        ...formErrors,
        classCapacity: t('create_course.basic_info.labels.errors.required_field'),
      };
    } else if (formStateOnSubmit.classCapacity <= 0) {
      formErrors = {
        ...formErrors,
        classCapacity: t('create_course.basic_info.labels.errors.capacity_threshold'),
      };
    } else if (course
      && course.content.enrolledStudents
      && formStateOnSubmit.classCapacity < course.content.enrolledStudents
    ) {
      formErrors = {
        ...formErrors,
        classCapacity: t('create_course.basic_info.labels.errors.capacity_reached'),
      };
    }
  
    return formErrors;
  };

  const handleCoTeacherBtnClick = () => {
    setShowCoTeacherField(!showCoTeacherField);
  };

  const RadioItems = Object.values(CourseTypeEnum).map((courseType) => {
    return {
      state: (formState.type === courseType ? 'Checked' : 'Unchecked') as RadioItemStateEnum,
      text: {
        value: t(`create_course.basic_info.course_type_labels.${courseType}`),
      },
      onClick: () => handleRadioSelection(courseType),
    };
  });

  const addNewCoTeacher = (coTeacherEmail: string): void => {
    if (coTeacherInputRef.current) {
      setFormState({
        ...formState,
        coTeachers: [
          ...(formState.coTeachers || []),
          coTeacherEmail,
        ],
      });
      coTeacherInputRef.current.clear();
      setCoTeacherInputValue('');
    }
  };

  const handleOnRemoveChip = (index: number) => {
    if (formState.coTeachers) {
      const coTeachers = [...formState.coTeachers];
      coTeachers.splice(index, 1);
      setFormState({
        ...formState,
        coTeachers: coTeachers,
      });
    }
  };

  const getCoTeacherErrorState = (state: CoTeacherErrorStates): CourseBasicInfoFormErrors | undefined => {
    let formErrorsState: CourseBasicInfoFormErrors | undefined;
    switch (state) {
      case CoTeacherErrorStates.default: { 
        formErrorsState = {
          ...formErrorState,
          coTeachers: '',
        };
        break;
      }
      case CoTeacherErrorStates.not_found: {
        formErrorsState = {
          ...formErrorState,
          coTeachers: t('create_course.basic_info.labels.errors.teacher_not_found'),
        };
        break;
      }
      case CoTeacherErrorStates.invalid: {
        formErrorsState = {
          ...formErrorState,
          coTeachers: t('create_course.basic_info.labels.errors.invalid_format'),
        };
        break;
      }
      default:
        break;
    }
    return formErrorsState;
  };

  const validateTeacherEmail = async (): Promise<CourseBasicInfoFormErrors | undefined> => {
    try {
      const brushedEmail = coTeacherInputValue.trim().toLowerCase();
      if (brushedEmail) {
        if (!isEmail(brushedEmail)) {
          return getCoTeacherErrorState(CoTeacherErrorStates.invalid);
        } else {
          const resp = await validateTeacher(brushedEmail);
          if (resp.isValid) {
            addNewCoTeacher(brushedEmail);
          } else {
            return getCoTeacherErrorState(CoTeacherErrorStates.not_found);
          }
        }
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.code === 404) {
        return getCoTeacherErrorState(CoTeacherErrorStates.not_found);
      }
    }
    return undefined;
  };

  const onCoTeacherInputChanged = (text: string) => {
    setCoTeacherInputValue(text);
    const formErrors = getCoTeacherErrorState(CoTeacherErrorStates.default);
    if (formErrors) {
      setFormErrorState(formErrors);
    }
  };

  const handleOnCoTeacherInputBlur = async () => {
    const formErrors = await validateTeacherEmail();
    if (formErrors) {
      setFormErrorState(formErrors);
    }
  };

  const onKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Tab':
        if (!coTeacherInputValue.length) {
          break;
        }
        // falls through
      case 'Enter':
      case ',':
      case ';':
        event.preventDefault();
        await handleOnCoTeacherInputBlur();
        break;
      case 'Escape':
        if (coTeacherInputRef.current) {
          coTeacherInputRef.current.blur();
        }
        break;
      default:
        break;
    }
  };

  const getIsFormValid = async (): Promise<boolean> => {
    const coTeachersErrors = await validateTeacherEmail();
    const formErrorsWithoutCoTeachers = validateForm(formState);
    const errorStateCombined = {
      ...formErrorsWithoutCoTeachers,
      coTeachers: coTeachersErrors?.coTeachers || '',
    };

    if (errorStateCombined) {
      setFormErrorState(errorStateCombined);
    }
    return (
      Object.values(errorStateCombined).filter((errorMessage: string) => !!errorMessage)
        .length === 0
    );
  };

  const handleSubmit = async () => {
    const isFormValid = await getIsFormValid();
    if (isFormValid) {
      const nextUrl = course ? `/courses/detail/${course.content.id}/classInfo` : COURSE_ROUTES.newCourse.step2;
      history.push(nextUrl, { 
        ...locationState,
        step1: formState,
      });
    }
  };

  return {
    ...props,
    stepperText: {
      value: t('stepperText', {
        currentStep: 1,
        totalSteps: 2,
      }),
    },
    textGroup: {
      topText: {
        value: t('create_course.basic_info.step_title'),
      },
      bottomText: {
        value: t('create_course.basic_info.step_description'),
      },
    },
    courseNameInputField: {
      state: formErrorState.name ? 'Error' : 'Default',
      text: {
        value: t('create_course.basic_info.labels.course_name'),
      },
      textInput: {
        textValue: formState.name,
        onTextChanged: handleTextChange('name'),
        inputRef: textInputRef,
      },
      contextualContent: {
        text: {
          value: formErrorState.name,
        },
      },
    },
    radioField: {
      labelText: {
        value: t('create_course.basic_info.labels.course_type'),
      },
      radioItemList: {
        type: 'Horizontal',
        radioItems: RadioItems,
        classes: {
          radioItem: styles.radioItem,
        },
      },
    },
    courseDescriptionTextAreaField: {
      state: formErrorState.description ? 'Error' : 'Default',
      text: {
        value: t('create_course.basic_info.labels.course_description'),
      },
      textArea: {
        classes: {
          input: styles.textArea,
        },
        textValue: formState.description,
        onTextChanged: handleTextChange('description'),
      },
      contextualContent: {
        text: {
          value: formErrorState.description,
        },
      },
    },
    classCapacityInputField: {
      state: formErrorState.classCapacity ? 'Error' : 'Default',
      text: {
        value: t('create_course.basic_info.labels.class_capacity'),
      },
      textInput: {
        textValue: isNaN(formState.classCapacity) ? '' : String(formState.classCapacity),
        onTextChanged: handleTextChange('classCapacity'),
        preProcessTextInput: preprocessNumber,
      },
      contextualContent: {
        text: {
          value: formErrorState.classCapacity,
        },
      },
    },
    addCoTeacher: {
      state: showCoTeacherField ? 'Expanded' : 'Default',
      button: {
        text: {
          value: t('create_course.basic_info.buttons.add_co_teachers'),
        },
        icon: {
          asset: 'AddSquare',
        },
        onClick: handleCoTeacherBtnClick,
      },
      chipField: {
        label: {
          value: t('create_course.basic_info.labels.co_teachers'),
        },
        allowNew: true,
        multiSelect: true,
        open: false,
        inputRef: coTeacherInputRef,
        autoFocus: !locationState?.step1,
        inputValue: coTeacherInputValue,
        onChipSaving: async () => {
          await validateTeacherEmail();
        },
        selectedOptions: normalizeCoTeacherRepresentation(
          formState.coTeachers,
          isViewerPrimaryTeacher ? undefined : course?.content?.primaryTeacher?.content?.email,
        ),
        handleOptionRemoval: handleOnRemoveChip,
        onBlur: handleOnCoTeacherInputBlur,
        onKeyDown: onKeyDown,
        onInputChange: onCoTeacherInputChanged,
        error: formErrorState.coTeachers ? 'Error' : 'None',
        contextualContent: {
          type: formErrorState.coTeachers ? 'Error' : 'Help',
          text: {
            value: formErrorState.coTeachers ? formErrorState.coTeachers : t('create_course.basic_info.hints.co_teacher'),
          },
        },
      },
    },
    button: {
      icon: {
        asset: 'ArrowRight',
      },
      text: {
        value: t('create_course.basic_info.buttons.next'),
      },
      onClick: handleSubmit,
    },
  };
};

export default usePresenter;
