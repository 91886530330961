import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { NOTIFICATION_DURATION, NOT_FOUND_ROUTE, SPOT_AVAILABLE_TOKEN_QUERY_PARAM, STUDENT_ROUTES } from '../../../lib/constants';
import { DataEntry } from '../../../lib/types';

import { Course, CourseStateType, RegistrationFlowEnum } from '../../../modules/course/types';
import { checkCourseAvailabilityUseCase, getCourseByIdUseCase, getInitialRegistrationCourseUseCase, validateSpotAvailableTokenUseCase } from './StudentKoruAccountPage.interactor';

import { StudentKoruAccountPageCombinedProps } from './types';
import { useNotification } from '../../../modules/notification';
import { verifyStudentAccountUseCase } from '../../blocks/StudentSignInBlock/StudentSignInBlock.interactor';
import { trackSuccessfulEmailVerification } from '../../../lib/trackingUtils';

const usePresenter = (props: StudentKoruAccountPageCombinedProps): StudentKoruAccountPageCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { trigger } = useNotification();

  const query = new URLSearchParams(search);
  const spotAvailableToken = query.get(SPOT_AVAILABLE_TOKEN_QUERY_PARAM);
  const verificationToken = query.get('token');
  const id = Number(query.get('id'));

  const [registrationFlow, setRegistrationFlow] = useState<RegistrationFlowEnum>(RegistrationFlowEnum.joinWaitlist);
  const { state } = useLocation<CourseStateType | undefined>();
  const [courseId, setCourseId] = useState<number | undefined>(undefined);
  const [course, setCourse] = useState<DataEntry<Course> | undefined>(state?.course);

  const { data: fetchedCourse, isLoading: isLoadingCourse } = useQuery(
    ['getCourseById', courseId],
    () => getCourseByIdUseCase(Number(courseId)),
    {
      enabled: !!courseId,
    },
  );

  const { data: initialRegistrationCourse, isLoading: isInitialRegistrationCourseLoading } = useQuery(
    ['getInitialRegistrationCourse', verificationToken],
    () => getInitialRegistrationCourseUseCase(verificationToken || ''),
    {
      enabled: !!verificationToken,
    },
  );

  // FIXME: Use enrolledStudents property from course
  const { data: hasAvailableSeats, isLoading: isLoadingHasAvailableSeats } = useQuery(
    ['getCourseAvailability', course?.content?.id],
    () => {
      if (course?.content?.id) {
        try {
          return checkCourseAvailabilityUseCase(course.content.id);
        } catch {
          return false;
        }
      }
    });

  const { mutateAsync: validateSpotAvailableToken } = useMutation(
    'validateSpotAvailableToken',
    async () => {
      if (spotAvailableToken) {
        return validateSpotAvailableTokenUseCase(spotAvailableToken);
      }
    },
  );

  const { mutateAsync: verifyAccount } = useMutation(['verifyAccount', id, verificationToken], async () => {
    if (id && verificationToken) {
      await verifyStudentAccountUseCase(id, verificationToken);
    }
  });

  useEffect(() => {
    if (verificationToken) {
      const verifyStudentAccount = async () => {
        await verifyAccount();
        trigger({
          type: 'Success',
          duration: NOTIFICATION_DURATION,
          message: t('notification.email_verified'),
        });
        trackSuccessfulEmailVerification();
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      verifyStudentAccount();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCourseId(initialRegistrationCourse?.id);
  }, [initialRegistrationCourse]);

  useEffect(() => {
    if (spotAvailableToken) {
      const startValidateSpotAvailableToken = async () => {
        try {
          const data = await validateSpotAvailableToken();
          if (data) {
            setCourseId(data.courseId);
          }
        } catch {
          history.replace(NOT_FOUND_ROUTE);
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      startValidateSpotAvailableToken();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasAvailableSeats) {
      setRegistrationFlow(RegistrationFlowEnum.register);
    }
  }, [hasAvailableSeats]);

  useEffect(() => {
    if (!course && !verificationToken && pathname !== STUDENT_ROUTES.auth.signIn && !spotAvailableToken) {
      history.replace(NOT_FOUND_ROUTE);
    }
  }, [course, history, pathname, spotAvailableToken, verificationToken]);

  useEffect(() => {
    if (fetchedCourse) {
      setCourse({
        ...fetchedCourse,
        content: {
          ...fetchedCourse.content,
          freeLinkToken: initialRegistrationCourse?.token,
        },
      });
    }
  }, [fetchedCourse, initialRegistrationCourse?.token]);

  const handleRegistrationButton = () => {
    if (course?.content.freeLinkToken) {
      history.push(`/courseRegistration/${course?.content?.id}?token=${course.content.freeLinkToken}`);
    } else {
      history.push(`/courseRegistration/${course?.content?.id}`);
    }
  };

  return {
    ...props,
    courseRegistrationSubheader: {
      titleText: {
        value: t('course_registration_subheader.title', { 
          registrationType: registrationFlow === RegistrationFlowEnum.register ? 
            t('registration_type.register') : t('registration_type.join_waitlist'),
          courseTitle: course?.content?.title }),
      },
      bodyText: {
        value: t('course_registration_subheader.body_text', { 
          courseType: t(`create_course.basic_info.course_type_labels.${course?.content?.courseType}`),
          courseId: course?.content?.id,
        }),
      },
      button: {
        text: {
          value: t('course_registration_subheader.button'),
        },
        icon: {
          asset: 'Close',
        },
        onClick: handleRegistrationButton,
      },
    },
    studentKoruAccountBlock: {
      registerFlow: registrationFlow,
    },
    studentSigninBlock: {
      course: course,
      registerFlow: registrationFlow,
      isCourseLoading: isLoadingCourse,
    },
    studentSignupBlock: {
      hasAvailableSeats,
    },
    verifyEmailBlock: {
      hasAvailableSeats,
    },
    confirmAttendenceBlock: {
      hasAvailableSeats,
    },
    isLoading: isInitialRegistrationCourseLoading || isLoadingHasAvailableSeats || isLoadingCourse,
  };
};

export default usePresenter;
