import React from 'react';
import { Trans } from 'react-i18next';

import i18n from '../modules/locale/i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTranslation = (transKey: string, values?: Record<string, unknown>, props?: any, components?: Record<string, unknown>): React.ReactNode => {
  return (
    <Trans
      i18n={i18n}
      i18nKey={transKey}
      values={values}
      components={{
        bold: <strong />,
        br: <br />,
        p: <p />,
        tr: <tr />,
        td: <td />,
        ol: <ol />,
        li: <li />,
        customElement: <span {...props} />,
        ...components,
      }}
    />
  );
};